import { useContext, useState } from "react";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { AuthContext } from "../../requests/auth/AuthContext";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { IconRefreshAlert } from "@tabler/icons";

const APIRequestButton = ({
                              isSubmitting,
                              isSubmitted: initialIsSubmitted,
                              showRefreshButton,
                          }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const { handleSubmit } = useFormikContext();
    const [isSubmitted, setIsSubmitted] = useState(initialIsSubmitted);
    const [refreshed, setRefreshed] = useState(false);

    const handleClick = () => {
        if (!isSubmitted && isAuthenticated) {
            handleSubmit();
            setIsSubmitted(true);
            setRefreshed(false);
        }
    };

    const handleRefresh = () => {
        setIsSubmitted((prevIsSubmitted) => !prevIsSubmitted);
        setRefreshed(!refreshed);
    };

    return (
        <>
            {!isAuthenticated && (
                <Typography>
                    You need to <Link to="/authentication">authenticate</Link> to perform this action.
                </Typography>
            )}
            <Button
                variant="contained"
                type="button"
                disabled={!isAuthenticated || isSubmitting || (isSubmitted && !refreshed && showRefreshButton)}
                onClick={handleClick}
            >
                Submit
            </Button>
            {showRefreshButton && isSubmitted && (
                <Tooltip title="Unlock the form for editing or resubmission">
                    <IconButton onClick={handleRefresh}>
                        <IconRefreshAlert />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default APIRequestButton;
