import React from "react";

// material-ui
import {Box, Divider, Grid, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import {gridSpacing} from 'store/constant';
import {useTheme} from "@mui/material/styles";

// assets
import {IconDownload} from "@tabler/icons";


const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};


const TransactionSummary = ({selectedTransaction}) => {
    const theme = useTheme();


    const sxDivider = {
        borderColor: theme.palette.secondary.main,
    };

    return (
        <Box maxHeight="90vh" overflowY="auto">
            {JSON.stringify(selectedTransaction, null, 2)}

            {/*<Grid container spacing={gridSpacing}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <SubCard title={`Payment Reference: ${transactionData.reference}`}*/}
            {/*                 secondary={*/}
            {/*                     <Tooltip title="Download A Copy">*/}
            {/*                         <IconButton*/}
            {/*                             color="primary"*/}
            {/*                             onClick={() => downloadPDF(selectedTransaction)}*/}
            {/*                         >*/}
            {/*                             <IconDownload size={32}/>*/}
            {/*                         </IconButton>*/}
            {/*                     </Tooltip>}>*/}
            {/*            <Grid container spacing={gridSpacing}>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Grid container spacing={3}>*/}
            {/*                        <Grid item xs={6} md={8}>*/}
            {/*                            <Typography variant="body2">*/}
            {/*                                <CalendarTodayTwoToneIcon sx={detailsIconSX}/> Book Date: {formattedDate}*/}
            {/*                            </Typography>*/}
            {/*                        </Grid>*/}
            {/*                        <Grid item xs={6} md={4} container justifyContent="flex-end" alignItems="center">*/}
            {/*                            <Stack spacing={3}>*/}
            {/*                                <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                    <Typography variant="subtitle1">Payment status:</Typography>*/}
            {/*                                    <TransactionStatusChip state={transactionData.state}/>*/}
            {/*                                </Stack>*/}
            {/*                            </Stack>*/}
            {/*                        </Grid>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Divider sx={sxDivider}/>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Grid container spacing={gridSpacing}>*/}
            {/*                        <Grid item xs={12}>*/}
            {/*                            <Stack spacing={2}>*/}
            {/*                                <Typography variant="h4">Payment Details</Typography>*/}
            {/*                                <Stack spacing={0}>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">Transaction ID: </Typography>*/}
            {/*                                        <Typography variant="body2">{transactionData.id}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography*/}
            {/*                                            variant="subtitle1">{transactionData.originator.details.name} sends:</Typography>*/}
            {/*                                        <Typography variant="body2">*/}
            {/*                                            {getFormattedCurrencyAmount(transactionData.amount, transactionData.currency)}*/}
            {/*                                        </Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography*/}
            {/*                                            variant="subtitle1">{transactionData.receiver.entityId} receives:</Typography>*/}
            {/*                                        <Typography variant="body2">*/}
            {/*                                            {getFormattedCurrencyAmount(receiverAmount, receiverCurrency)}*/}
            {/*                                        </Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    {hasFxAmount && 'rate' in transactionData.fx && (*/}
            {/*                                        <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                            <Typography variant="subtitle1">FX Rate:</Typography>*/}
            {/*                                            <Typography*/}
            {/*                                                variant="body2">{transactionData.fx.rate}</Typography>*/}
            {/*                                        </Stack>*/}
            {/*                                    )}*/}
            {/*                                </Stack>*/}
            {/*                            </Stack>*/}
            {/*                        </Grid>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Divider sx={sxDivider}/>*/}
            {/*                </Grid>*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Grid container spacing={gridSpacing}>*/}
            {/*                        <Grid item sm={6}>*/}
            {/*                            {renderBankDetails(transactionData.receiver.details, transactionData.receiver.entityId)}*/}
            {/*                        </Grid>*/}
            {/*                        <Grid item sm={6}>*/}
            {/*                            <Stack spacing={2}>*/}
            {/*                                <Typography*/}
            {/*                                    variant="h4">{transactionData.originator.details.name} Details</Typography>*/}
            {/*                                <Stack>*/}
            {/*                                    <Stack direction="row" spacing={2} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">Address:</Typography>*/}
            {/*                                        <Typography*/}
            {/*                                            variant="body2">{transactionData.originator.details.address.street1}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">City:</Typography>*/}
            {/*                                        <Typography*/}
            {/*                                            variant="body2">{transactionData.originator.details.address.city}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">Country:</Typography>*/}
            {/*                                        <Typography*/}
            {/*                                            variant="body2">{transactionData.originator.details.address.country}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">State:</Typography>*/}
            {/*                                        <Typography*/}
            {/*                                            variant="body2">{transactionData.originator.details.address.regionCode}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                    <Stack direction="row" spacing={1} alignItems="center">*/}
            {/*                                        <Typography variant="subtitle1">Zip code:</Typography>*/}
            {/*                                        <Typography*/}
            {/*                                            variant="body2">{transactionData.originator.details.address.postalCode}</Typography>*/}
            {/*                                    </Stack>*/}
            {/*                                </Stack>*/}
            {/*                            </Stack>*/}
            {/*                        </Grid>*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </SubCard>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Box>
    )
        ;
};

export default TransactionSummary;