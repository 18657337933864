import React, {useContext, useEffect, useState} from 'react';
// materialUI imports
import {Box, Grid, Typography} from '@mui/material';

// project imports
import APIRequestButton from '../ui-component/extended/APIRequestButton';
import {ResponseDisplay} from './response/ResponseDisplay';
import CustomDropdown from '../ui-component/CustomDropdown';
import CustomFormField from '../ui-component/CustomFormField';
import {useAuthHeaders} from "./AuthHeaders";
import ApiUrlContext from "../ApiUrlContext";

// 3rd Party imports
import axios from 'axios';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";


// ============================|| Request - Ledger ||============================ //

const CreateFBOLedgerSection = () => {
    const responseRef = React.useRef(null);

    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const [countries, setCountries] = useState([]);
    const authHeaders = useAuthHeaders();


    useEffect(() => {
        axios.get('https://restcountries.com/v2/all')

            .then(response => {
                // Transform the response data into a suitable format for the dropdown
                const countryOptions = response.data.map(country => ({
                    key: country.alpha2Code,
                    value: country.alpha2Code,
                    label: country.name
                }));

                // Sort the countries alphabetically by their label
                countryOptions.sort((a, b) => a.label.localeCompare(b.label));

                setCountries(countryOptions);
            })
            .catch(error => {
                console.error('Error fetching countries:', error);
            });
    }, []);

    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [response]);


    const InfoText = ({text}) => showInfo ?
        <Typography variant="caption" sx={{color: '#C41C90'}}>{text}</Typography> : null;

    const handleSubmit = async (values) => {
        const addressData = {
            country: values.country.toUpperCase(),
            street1: values.street1,
            city: values.city,
            regionCode: values.regionCode.toUpperCase(),
            postalCode: values.postalCode.toUpperCase()
        };

        if (values.street2) {
            addressData.street2 = values.street2;
        }

        const requestData = {
            fbo: {
                type: values.type,
                address: addressData,
                name: values.name,
                shortName: values.shortName,
            },
            currency: values.currency,
            note: values.note,
            accounts: values.accounts,
        };

        try {
            const options = {
                method: 'POST',
                url: `${apiUrl}/ledger`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            setResponse(response.data);
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage();
            }
            console.error('Error:', error);
        }
    };


    return (
        <Box mt={2}>
            <div
                onClick={() => setShowInfo(prev => !prev)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '5px'
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                    Need Help?
                </Typography>
            </div>
            <Formik
                initialValues={{
                    country: '',
                    street1: '',
                    city: '',
                    regionCode: '',
                    postalCode: '',
                    name: '',
                    shortName: '',
                    currency: 'USD',
                    note: '',
                    type: '',
                    accounts: 1,
                }}
                validationSchema={Yup.object().shape({
                    country: Yup.string().required('Country is required'),
                    street1: Yup.string().required('Street 1 is required'),
                    city: Yup.string().required('City is required'),
                    regionCode: Yup.string()
                        .required('Region Code is required')
                        .matches(/^[A-Za-z]{2}$/, 'Region Code must be exactly two letters'),
                    postalCode: Yup.string().required('Postal Code is required'),
                    street2: Yup.string(),
                    name: Yup.string().required('Name is required'),
                    shortName: Yup.string().required('Short Name is required'),
                    currency: Yup.string().required('Currency is required'),
                    accounts: Yup.number().required('Accounts is required'),
                    type: Yup.string().required('Type is required'),
                    note: Yup.string().required('Note is required'),

                })}
                onSubmit={handleSubmit}
            >
                {({values, handleChange, handleBlur, isSubmitting, touched, errors}) => (


                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InfoText text="This is the name of account holder"/>
                                <CustomFormField
                                    name="name"
                                    label="Name"
                                    value={values.name}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoText text='This is a "nick name" for the account'/>
                                <CustomFormField
                                    name="shortName"
                                    label="Short Name"
                                    value={values.shortName}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InfoText text='Is the FBO for a business or a person?'/>

                                <CustomDropdown
                                    name="type"
                                    label="Type"
                                    value={values.type}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'person', label: 'Person'},
                                        {value: 'company', label: 'Company'},
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomDropdown
                                    name="country"
                                    label="Country"
                                    value={values.country}
                                    handleChange={
                                        handleChange
                                    }
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={countries}
                                    maxHeight={"60vh"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomFormField
                                    name="regionCode"
                                    label="Region Code"
                                    value={values.regionCode}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="street1"
                                    label="Street 1"
                                    value={values.street1}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="street2"
                                    label="Street 2"
                                    value={values.street2}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomFormField
                                    name="city"
                                    label="City"
                                    value={values.city}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomFormField
                                    name="postalCode"
                                    label="Zip Code"
                                    value={values.postalCode}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InfoText text={"Currently we only support USD accounts"}/>

                                <CustomDropdown
                                    name="currency"
                                    label="Currency"
                                    value={values.currency}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'USD', label: 'USD'},
                                        // Add more currency options as needed
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InfoText
                                    text={"The number of sequential accounts requested. If not specified, this defaults to 1."}
                                />
                                <CustomFormField
                                    name="accounts"
                                    label="Accounts"
                                    value={values.accounts}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InfoText text={'An optional note / description for this ledger.'}/>
                                <CustomFormField
                                    name="note"
                                    label="Note"
                                    value={values.note}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <APIRequestButton isSubmitting={isSubmitting}/>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            {response && (
                <Box mt={2} ref={responseRef}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateFBOLedgerSection;
