import React, {useState} from "react";

// material-ui
import {Button, Grid, Typography} from "@mui/material";

// project imports
import MainCard from "./ui-component/cards/MainCard";
import CreateEntityModal from "./requests/CreateEntityModal";
import AnimateButton from "./ui-component/extended/AnimateButton";

// third-party
import {IconArrowDown, IconCirclePlus} from "@tabler/icons";
import GetEntities from "./requests/GetEntities";

// ============================|| PAGE - Entity ||============================ //
const Entity = () => {
    const [isCreateEntityModalOpen, setIsCreateEntityModalOpen] = useState(false);
    const [isHidden, setIsHidden] = useState(true); // state to control hidden section

    const handleToggleHiddenSection = () => {
        setIsHidden(!isHidden);
    };

    const handleCreateEntityModalOpen = () => {
        setIsCreateEntityModalOpen(true);
    };

    const handleCreateEntityModalClose = () => {
        setIsCreateEntityModalOpen(false);
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} lg={10} xl={8}>
                <MainCard
                    title={"Entity Requests"}
                    children={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: "18px" }}>
                                    These request will produce responses containing the latest
                                    details related to Entities and allow for the creation of a new entity.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleToggleHiddenSection}
                                        sx={{ width: "100%", fontSize: "18px" }}
                                        endIcon={isHidden ? <IconCirclePlus /> : <IconArrowDown />}
                                    >
                                        View Entities
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {!isHidden && (
                                <Grid item xs={12}>
                                    <GetEntities />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleCreateEntityModalOpen}
                                        sx={{ width: "100%", fontSize: "18px" }}
                                        endIcon={<IconCirclePlus />}
                                    >
                                        Create Entity
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    }
                />
            </Grid>
            <CreateEntityModal
                open={isCreateEntityModalOpen}
                handleClose={handleCreateEntityModalClose}
            />
        </Grid>
    );
};

export default Entity;

