import React, {useContext, useState} from 'react';
// materialUI imports
import {Box, Modal, TextField, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//project imports
import {useAuthHeaders} from "./AuthHeaders";
import APIRequestButton from "../ui-component/extended/APIRequestButton";
//3rd party imports
import axios from 'axios';
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import CustomFormField from "../ui-component/CustomFormField";
import CustomDropdown from "../ui-component/CustomDropdown";
import {ResponseDisplay} from "./response/ResponseDisplay";
import {DatePicker} from '@mui/x-date-pickers';
import ApiUrlContext from "../ApiUrlContext";
import {AuthContext, handleRemoveLocalStorage} from "./auth/AuthContext";

// ============================|| Create - Entity ||============================ //
const CreateEntityModal = ({open, handleClose}) => {
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const apiUrl = useContext(ApiUrlContext);
    const {setIsAuthenticated} = useContext(AuthContext);
    const authHeaders = useAuthHeaders();


    const InfoText = ({text}) => showInfo ?
        <Typography variant="caption" sx={{color: '#C41C90'}}>
            {text}
        </Typography> : null;
    const handleFormSubmit = async (values) => {
        const requestData = JSON.stringify({
            details: {
                ach: {
                    type: values.achType,
                    aba: values.aba,
                    account: values.account,
                },
                name: values.name,
                dob: values.dob,
                type: values.type,
            },
            entityId: values.entityId,
        });

        try {
            const response = await axios.post(`${apiUrl}/entity`, requestData, {
                headers: authHeaders,
            });
            setResponse(response.data);
        } catch (error) {
            if (error.response && error.response.data.error.code === "401") {
                // Handle 401 Unauthorized response
                setIsAuthenticated(false);
                handleRemoveLocalStorage()
            }
            console.error('Error:', error);
        }
    };


    const DatepickerField = ({field, form, ...other}) => {
        const currentError = form.errors[field.name];

        return (
            <DatePicker
                {...field}
                {...other}
                helperText={currentError}
                error={Boolean(currentError)}
                value={field.value}
                disableFuture
                onChange={date => form.setFieldValue(field.name, date)}
                renderInput={(params) => <TextField {...params} />}
                sx={{
                    '& .MuiInputBase-input': {
                        padding: '21px'
                    },
                    '& .MuiInputLabel-root': {
                        top: '5px', // Adjust as needed
                    }
                }}
            />
        );
    };
    const handleCloseModal = () => {
        setResponse(null);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    maxWidth: '95%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Create Entity Request
                    </Typography>
                    <IconButton onClick={handleCloseModal} aria-label="close" size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div
                    onClick={() => setShowInfo(prev => !prev)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '5px'
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                        Need Help?
                    </Typography>
                </div>
                <Box sx={{maxHeight: '80vh', overflow: 'auto'}}>
                    <Formik
                        initialValues={{
                            achType: '',
                            aba: '',
                            account: '',
                            name: '',
                            type: '',
                            entityId: '',
                            description: '',
                        }}
                        validationSchema={Yup.object().shape({
                            achType: Yup.string().required('ACH Type is required'),
                            aba: Yup.string()
                                .required('ABA is required')
                                .length(9, 'ABA must be exactly 9 digits')
                                .matches(/^\d+$/, 'ABA must contain only digits'),
                            account: Yup.string().required('Account is required'),
                            name: Yup.string().required('Name is required'),
                            type: Yup.string().required('Type is required'),
                            entityId: Yup.string().required('Entity Reference is required'),
                            description: Yup.string().required('Description is required'),
                        })}
                        onSubmit={handleFormSubmit}
                    >
                        {({values, handleChange, handleBlur, setFieldValue, isSubmitting, touched, errors}) => (
                            <Form>
                                <InfoText text={"The client's ID for the entity. WireFX will check that this\n" +
                                    "is unique, but otherwise can be any string you want."}/>
                                <CustomFormField
                                    name="entityId"
                                    label="Entity Reference"
                                    value={values.entityId}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                                <CustomDropdown
                                    name="type"
                                    label="Entity Type"
                                    value={values.type}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'person', label: 'Person'},
                                        {value: 'company', label: 'Company'},
                                    ]}

                                />
                                <CustomFormField
                                    name="name"
                                    label="Name"
                                    value={values.name}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                                {values.type === 'person' &&
                                    <Field
                                        name="dob"
                                        label="Date of Birth"
                                        component={DatepickerField}
                                    />
                                }
                                <ErrorMessage name="dob" component="div"/>
                                <CustomDropdown
                                    name="achType"
                                    label="ACH Type"
                                    value={values.achType}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    options={[
                                        {value: 'dda', label: 'Demand Deposit Account'},
                                        {value: 'savings', label: 'Savings'},
                                    ]}

                                />
                                <InfoText text={"The ABA routing number for this entity."}/>
                                <CustomFormField
                                    name="aba"
                                    label="ABA"
                                    value={values.aba}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                                <InfoText text={"Account number for Entity's Banks"}/>
                                <CustomFormField
                                    name="account"
                                    label="Account"
                                    value={values.account}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                                <CustomFormField
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />

                                <APIRequestButton isSubmitting={isSubmitting}/>
                            </Form>
                        )}
                    </Formik>
                </Box>

                {response && (
                    <Box sx={{mt: 2}}>
                        <ResponseDisplay response={response}/>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default CreateEntityModal;